.error-view-title {
    font-size: 24px;
}

.error-view-subtitle {
    font-size: 18px;
}

.error-view-container {
    margin: 0 30px;
}